<template>
  <footer class="bg-gray-900" aria-labelledby="footer-heading">
    <div class="pt-24 sm:pt-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-white">A HOMEINFO számokban - építkezés, felújítás, lakberendezés témában:</h2>
        <div class="mx-auto mt-10 grid max-w-lg grid-cols-2 items-center gap-x-4 gap-y-10 sm:max-w-xl sm:grid-cols-6 lg:mx-0 lg:max-w-none lg:grid-cols-7 text-white text-center ">
          <div class="bg-white text-gray-900 p-4 h-full flex flex-col justify-between">
            <strong class="text-3xl">1 600</strong>szakmai cikk
          </div>
          <div class="bg-white text-gray-900 p-4 h-full flex flex-col justify-between">
            <strong class="text-3xl">33 000</strong>inspiráló kép
          </div>
          <div class="bg-white text-gray-900 p-4 h-full flex flex-col justify-between">
            <strong class="text-3xl">2 900</strong>lakásbemutató
          </div>
          <div class="bg-white text-gray-900 p-4 h-full flex flex-col justify-between">
            <strong class="text-3xl">12 000</strong>termék
          </div>
          <div class="bg-white text-gray-900 p-4 h-full flex flex-col justify-between">
            <strong class="text-3xl">90</strong>peempee vásárlási lista
          </div>
          <div class="bg-white text-gray-900 p-4 h-full flex flex-col justify-between">
            <strong class="text-3xl">88</strong>szerző
          </div>
          <div class="bg-white text-gray-900 p-4 h-full flex flex-col justify-between">
            <strong class="text-2xl">90 000</strong>fős Facebook csoport
          </div>
        </div>
      </div>
    </div>
    <div class="pt-24 sm:pt-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-white">Kiállításaink</h2>
        <div class="mx-auto mt-10 grid max-w-lg grid-cols-2 lg:grid-cols-4 items-center gap-x-4 gap-y-10 sm:max-w-xl lg:mx-0 lg:max-w-none">
          <a href="https://www.homeinfo.hu/furdoszoba-kiallitas/" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" loading="lazy" src="https://www.homeinfo.hu/assets/fs580.webp" alt="Fürdőszoba és otthoni wellness kiállítás plakát" /></a>
          <a href="https://www.homeinfo.hu/butor-lampa-lakastextil-kiallitas/" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" loading="lazy" src="https://www.homeinfo.hu/assets/vk580.webp" alt="Bútor, lámpa, lakástextil kiállítás plakát" /></a>
          <a href="https://www.homeinfo.hu/burkoloi-workshop/" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" loading="lazy" src="https://www.homeinfo.hu/assets/bw580.webp" alt="Burkolói workshop plakát" /></a>
          <a href="https://www.homeinfo.hu/emw/" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1 w-full" loading="lazy" src="https://www.homeinfo.hu/assets/emw580.webp" alt="Építőipari mester workshow plakát" /></a>
        </div>
      </div>
    </div>
    <SupportersList />
    <PreviousMeetings />
    <div class="pt-24 sm:pt-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 class="text-center text-lg font-semibold leading-8 text-white">HOMEINFO és a LOSZ által alapított díjak</h2>
        <div class="mx-auto mt-10 grid max-w-lg grid-cols-2 lg:grid-cols-4 items-center gap-x-10 gap-y-10 sm:max-w-xl sm:grid-cols-6 lg:mx-0 lg:max-w-none lg:grid-cols-7">
          <a href="https://www.homeinfo.hu/ev-furdoszobaja/dijazottak/" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1" loading="lazy" src="https://www.homeinfo.hu/ev-furdoszobaja/assets/badge_evfurdoszobaja2024.png" alt="Az év fürdőszobája 2024 díj" /></a>
          <a href="https://www.homeinfo.hu/ev-szobaja-2024/dijazottak/" target="_blank" rel="nofollow"><img class="col-span-2 lg:col-span-1" loading="lazy" src="https://www.homeinfo.hu/ev-szobaja-2024/assets/badge_evszobaja2024.png" alt="Az év szobája 2024 díj" /></a>
        </div>
      </div>
    </div>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="space-y-4">
          <img class="h-7" :src="appLogo" :alt="appName" />
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff" width="15px" height="15px" class="mr-2 mt-1"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z"/></svg><a class="text-gray-300 hover:text-white" href="tel:+36307987643">+36/30-798-7643</a>
          </div>
          <div class="flex">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff" width="15px" height="15px" class="mr-2 mt-1"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48L48 64zM0 176L0 384c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-208L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"/></svg><a class="text-gray-300 hover:text-white" href="mailto:info@homeinfo.hu">info@homeinfo.hu</a>
          </div>
          <div class="flex space-x-6">
            <a v-for="item in navigation.social" :key="item.name" :href="item.href" class="text-gray-500 hover:text-gray-400">
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
            </a>
          </div>
        </div>
        <div class="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 class="text-sm font-semibold leading-6 text-white">Rólunk</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.about" :key="item.name">
                  <router-link :to="{ name: item.routeName }" class="text-sm leading-6 text-gray-300 hover:text-white">{{ item.name }}</router-link>
                </li>
              </ul>
            </div>
            <div class="mt-10 md:mt-0">
              <h3 class="text-sm font-semibold leading-6 text-white">Információk</h3>
              <ul role="list" class="mt-6 space-y-4">
                <li v-for="item in navigation.information" :key="item.name">
                  <router-link v-if="!item.href" :to="{ name: item.routeName }" class="text-sm leading-6 text-gray-300 hover:text-white">
                    {{ item.name }}
                  </router-link>
                  <a v-else :href="item.href" class="text-sm leading-6 text-gray-300 hover:text-white" target="_blank">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
        <p class="text-xs leading-5 text-gray-400">Copyright &copy; {{ currentYear }} {{appName}}</p>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { defineComponent, h } from 'vue'

const navigation = {
  company_data: [
  ],
  information: [
    /*{ name: 'Bejelentkezés', routeName: 'LoginPage' },
    { name: 'Regisztráció', routeName: 'RegistrationPage' },*/
    { name: 'ÁSZF', routeName: 'GeneralTermsPage' },
    { name: 'Piactér ÁSZF', routeName: 'MarketplaceGeneralTermsPage' },
    { name: 'Adatkezelési tájékoztató', routeName: 'PrivacyPage' },
    { name: 'Impresszum', href: 'https://www.homeinfo.hu/impresszum' },
  ],
  about: [
    /*{ name: 'Rólunk', routeName: 'AboutPage' },*/
    { name: 'Kapcsolat', routeName: 'ContactPage' },
    { name: 'GYIK', routeName: 'FAQPage' },
  ],
  partners: [
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/Homeinfo.hu/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/homeinfo.hu/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'TikTok',
      href: 'https://www.tiktok.com/@homeinfo.hu',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              d: 'M16.6 5.82s.51.5 0 0A4.28 4.28 0 0 1 15.54 3h-3.09v12.4a2.59 2.59 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/user/HomeinfoHU',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/in/homeinfo-hu-78935586/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 448 512' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
  ],
}
</script>

<script>
import { mapGetters } from 'vuex'
import SupportersList from '@/components/Supporters.vue';
import PreviousMeetings from '@/components/PreviousMeetings.vue';

export default {
  name: 'FooterPage',
  computed: {
    ...mapGetters(['apiUrl', 'appName', 'appLogo'])
  },
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>