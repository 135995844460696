<template>
  <div v-if="isVisible" :class="['rounded-md p-4 fixed bottom-4 left-4 z-20', alertClass]">
    <div class="absolute top-0 left-0 h-1" :class="progressClass" :style="{ width: progress + '%' }"></div>
    <div class="flex">
      <div class="shrink-0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" :class="iconClass" width="20px" height="20px" fill="currentColor"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>
      </div>
      <div class="ml-3">
        <p class="text-sm font-medium">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineProps, ref, watch } from 'vue'

export default {
  name: 'Alert',
  props: {
    isVisible: Boolean,
    type: {
      type: String,
      default: 'success',
    },
    message: String,
    progress: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    alertClass() {
      return this.type === 'success' ? 'bg-green-50' : 'bg-red-50';
    },
    progressClass() {
      return this.type === 'success' ? 'bg-green-500' : 'bg-red-500';
    },
    iconClass() {
      return this.type === 'success' ? 'text-green-400' : 'text-red-400';
    },
  }
}
</script>