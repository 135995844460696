import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

const initialState = () => ({
  products: [],
  appLogo: require('@/assets/homeinfo-shop-logo.png'),
  apiUrl: 'https://shopadmin.homeinfo.hu/api',
  appName: 'HOMEINFO Shop',
  cart: {},
  selectCategory: {},
  recentlyViewedProducts: [],
  favorite: [],
  compare: [],
  localStorageEnabled: false,
});

export default createStore({
  state: initialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, initialState());
    },
    setProducts(state, products) {
      state.products = products;
    },
    addToCart(state, product) {
      const vendorKey = product.vendor;
      if (!state.cart[vendorKey]) {
        state.cart[vendorKey] = [];
      }

      const existingProduct = state.cart[vendorKey].find(item => item.id === product.id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        state.cart[vendorKey].push(product);
      }
    },
    removeFromCart(state, { vendor, productId }) {
      if (state.cart[vendor]) {
        state.cart[vendor] = state.cart[vendor].filter(item => item.id !== productId);
      }
    },
    updateCartQuantity(state, { vendor, productId, quantity }) {
      const product = state.cart[vendor]?.find(item => item.id === productId);
      if (product) {
        product.quantity = quantity;
      }
    },
    setSelectedCategory(state, child) {
      state.selectCategory = {
        id: child.id,
        name: child.name,
        slug: child.slug,
        category_id: child.category_id ? child.category_id : child.id,
      };
    },
    addRecentlyViewedProducts(state, product) {
      if (state.recentlyViewedProducts.some(p => p.id === product.id)) {
        return;
      }

      if (state.recentlyViewedProducts.length >= 8) {
        state.recentlyViewedProducts.shift();
      }

      state.recentlyViewedProducts.push(product);
    },
    clearAll(state) {
      state.cart = {};
      state.recentlyViewedProducts = [];
      state.selectCategory = {};
      state.products = [];
    },
    toggleFavorite(state, product) {
      //max érték be
      const productId = product.id;

      const existingProductIndex = state.favorite.findIndex(item => item.id === productId);

      if (state.favorite.length >= 12) {
        state.favorite.shift();
      }
      
      if (existingProductIndex === -1) {
        state.favorite.push(product);
      } else {
        state.favorite.splice(existingProductIndex, 1);
      }
    },
    toggleCompare(state, product) {
      const productId = product.id;

      const existingProductIndex = state.compare.findIndex(item => item.id === productId);

      if (state.compare.length >= 4) {
        state.compare.shift();
      }
      
      if (existingProductIndex === -1) {
        state.compare.push(product);
      } else {
        state.compare.splice(existingProductIndex, 1);
      }
    },
  },
  actions: {
    fetchProducts({ commit }) {
      const products = [/* termékek betöltése */];
      commit('setProducts', products);
    },
    addToCart({ commit }, product) {
      commit('addToCart', product);
    },
    removeFromCart({ commit }, productId) {
      commit('removeFromCart', productId);
    },
    setSelectedCategory({ commit }, child) {
      commit('setSelectedCategory', child);
    },
    addRecentlyViewedProducts({ commit }, product) {
      commit('addRecentlyViewedProducts', product);
    },
    toggleFavorite({ commit }, product) {
      commit('toggleFavorite', product);
    },
    toggleCompare({ commit }, product) {
      commit('toggleCompare', product);
    },
  },
  getters: {
    allProducts: state => state.products,
    apiUrl: state => state.apiUrl,
    appName: state => state.appName,
    appLogo: state => state.appLogo,
    localStorageEnabled: state => state.localStorageEnabled,
    cartItems: state => {
      return Object.values(state.cart || {}).flat();
    },
    cartItemCount: state => {
      if (!state.cart || Object.keys(state.cart).length === 0) return 0;

      return Object.values(state.cart).reduce((count, vendorItems) => {
        return count + vendorItems.reduce((vendorCount, item) => vendorCount + item.quantity, 0);
      }, 0);
    },
    selectCategory: (state) => state.selectCategory,
    recentlyViewedProducts: (state) => state.recentlyViewedProducts,
    isFavorite: (state) => (productId) => {
      return state.favorite.some(item => item.id === productId);
    },
    favoriteCount(state) {
      return Object.keys(state.favorite).length;
    },
    favoriteProducts(state) {
      return Object.values(state.favorite);
    },
    isCompare: (state) => (productId) => {
      return state.compare.some(item => item.id === productId);
    },
    compareCount(state) {
      return Object.keys(state.compare).length;
    },
    compareProducts(state) {
      return Object.values(state.compare);
    },
  },
  plugins: [createPersistedState()],
});
