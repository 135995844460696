<template>
  <div class="pt-24 sm:pt-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="text-center text-lg font-semibold leading-8 text-white">A HOMEINFO   kiemelt partnerei</h2>
      <div class="relative mt-10">
        <div class="overflow-x-auto whitespace-nowrap py-4 scrollbar-hidden" ref="logoContainer" @mousedown="startDrag" @touchstart="startDrag" @mouseup="stopDrag" @touchend="stopDrag" @mousemove="onDrag" @touchmove="onDrag" @mouseleave="stopDrag" @touchcancel="stopDrag">
          <div class="flex justify-start items-center space-x-10" ref="logos">
            <!--a href="https://arezzodesign.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/arezzo-design.png" alt="Arezzo márka logó" /></a-->
            <a href="https://dublinohome.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/dublino-home.png" alt="Dublino home márka logó" /></a>
            <a href="https://elter.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/elter.png" alt="Elter márka logó" /></a>
            <a href="https://www.geberit.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/geberit.png" alt="Geberit márka logó" /></a>
            <a href="https://www.lampacenter.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/horanyi.png" alt="Horányi márka logó" /></a>
            <a href="https://www.kludi.com/hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/kludi.png" alt="Kludi márka logó" /></a>
            <a href="https://www.lederland.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/lederland.png" alt="Lederland márka logó" /></a>
            <a href="https://m-acryl.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/macryl.png" alt="M-acryl márka logó" /></a>
            <a href="https://www.mofem.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/mofem.png" alt="Mofém márka logó" /></a>
            <a href="https://www.natuzzi.com/hu/en/natuzzi-editions" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/natuzzi.png" alt="Natuzzi márka logó" /></a>
            <a href="https://www.radaway.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/radaway.png" alt="Radaway márka logó" /></a>
            <a href="https://www.ravak.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/ravak.png" alt="Ravak márka logó" /></a>
            <a href="https://www.saphokft.hu/shop/default.aspx" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/sapho.png" alt="Sapho márka logó" /></a>
            <a href="https://spatrend.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/spatrend.png" alt="Spatrend márka logó" /></a>
            <a href="https://strohm-teka.com/hu/?set_region=hu" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/strohm_teka.png" alt="Strohm teka márka logó" /></a>
            <a href="https://varrodesign.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/vilagitas-kiallitas/assets/140x140/varrodesign.png" alt="Varrodesign márka logó" /></a>
            <a href="https://vitalspa.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/vitalspa.png" alt="Vitalspa marka logó" /></a>
            <a href="https://www.wellis.com/hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/140x140/wellis.png" alt="Wellis márka logó" /></a>
          </div>
        </div>
        <button @click="scrollRight" class="absolute right-[-15px] top-1/2 transform -translate-y-1/2 p-2 bg-gray-500 hover:bg-gray-700 text-white rounded-full z-10"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="size-4" fill="#fff"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SupportersList',
  data() {
    return {
      isDragging: false,
      startX: 0,
      initialScrollLeft: 0,
    };
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.logoContainer;
      container.scrollBy({ left: -150, behavior: 'smooth' });
    },
    scrollRight() {
      const container = this.$refs.logoContainer;
      container.scrollBy({ left: 150, behavior: 'smooth' });
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.type === 'mousedown' ? e.pageX : e.touches[0].pageX;
      this.initialScrollLeft = this.$refs.logoContainer.scrollLeft;
      e.preventDefault();
    },
    stopDrag() {
      this.isDragging = false;
    },
    onDrag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.type === 'mousemove' ? e.pageX : e.touches[0].pageX;
      const walk = (x - this.startX) * 2;
      this.$refs.logoContainer.scrollLeft = this.initialScrollLeft - walk;
    },
  }
};
</script>

<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>