<template>
  <div class="pt-24 sm:pt-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="text-center text-lg font-semibold leading-8 text-white">Hol találkozhatott már a HOMEINFO-val?</h2>
      <div class="relative mt-10">
        <!--button @click="scrollLeft" class="absolute left-0 top-1/2 transform -translate-y-1/2 p-2 bg-gray-700 text-white rounded-full z-10">&lt;</button-->
        <div class="overflow-x-auto whitespace-nowrap py-4 scrollbar-hidden" ref="logoContainer" @mousedown="startDrag" @touchstart="startDrag" @mouseup="stopDrag" @touchend="stopDrag" @mousemove="onDrag" @touchmove="onDrag" @mouseleave="stopDrag" @touchcancel="stopDrag">
          <div class="flex justify-start items-center space-x-10" ref="logos">
            <!--div class="flex-shrink-0 w-[50px] sm:w-[500px]"></div--> 
            <a href="https://anyakanyar.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/anyakanyar.png" alt="Anyakanyar logó" /></a>
            <a href="https://www.instagram.com/bodza.interior.design/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/bodza.png" alt="Bodza interior design logó" /></a>
            <a href="https://www.csaladinet.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/csaladinet.png" alt="Csaladinet logó" /></a>
            <a href="https://blog.dekoresmentha.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/dekor-es-mentha.png" alt="Dekor és Mentha logó" /></a>
            <a href="https://dunapartprogram.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/dunapart.png" alt="Duna-part program logó" /></a>
            <a href="https://hirtv.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/hirtv.png" alt="Hír tv logó" /></a>
            <a href="https://www.hna-design.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/hna.png" alt="Home and architecture logó" /></a>
            <a href="https://jazzy.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/jazzy.png" alt="90.9 jazzy rádió logó" /></a>
            <a href="https://karcfm.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/karcfm.png" alt="Karc fm logó" /></a>
            <a href="https://www.lakaskultura.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/lakaskultura.png" alt="Lakáskultúra logó" /></a>
            <a href="https://www.facebook.com/Lakberendezod/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/lakberendezod.png" alt="Lakberendeződ logó" /></a>
            <a href="https://lakberinfo.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/lakberinfo.png" alt="Lakberinfo logó" /></a>
            <a href="https://www.noklapja.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/noklapja.png" alt="Nők lapja logó" /></a>
            <a href="https://www.otthon.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/otthon.png" alt="Otthon logó" /></a>
            <a href="https://www.praktika.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/praktika.png" alt="Praktika logó" /></a>
            <a href="https://rendegykattintasra.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/rend.png" alt="Rend logó" /></a>
            <a href="https://rtl.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/rtlklub.png" alt="RTL klub logó" /></a>
            <a href="https://spabook.net/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/spabook.png" alt="Spabook logó" /></a>
            <a href="https://stiledivita.blog.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/stile.png" alt="Stile di vita logó" /></a>
            <a href="https://tv2play.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/tv2.png" alt="TV2 play logó" /></a>
            <a href="https://www.viasat3.hu/" target="_blank" rel="nofollow" class="bg-white contents"><img class="col-span-2 lg:col-span-1 w-full p-2" loading="lazy" src="https://www.homeinfo.hu/furdoszoba-kiallitas/assets/media/viasat3.png" alt="Viasat 3 logó" /></a>
          </div>
        </div>
        <button @click="scrollRight" class="absolute right-[-15px] top-1/2 transform -translate-y-1/2 p-2 bg-gray-500 hover:bg-gray-700 text-white rounded-full z-10"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="size-4" fill="#fff"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/></svg></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviousMeetings',
  data() {
    return {
      isDragging: false,
      startX: 0,
      initialScrollLeft: 0,
    };
  },
  methods: {
    scrollLeft() {
      const container = this.$refs.logoContainer;
      container.scrollBy({ left: -150, behavior: 'smooth' });
    },
    scrollRight() {
      const container = this.$refs.logoContainer;
      container.scrollBy({ left: 150, behavior: 'smooth' });
    },
    startDrag(e) {
      this.isDragging = true;
      this.startX = e.type === 'mousedown' ? e.pageX : e.touches[0].pageX;
      this.initialScrollLeft = this.$refs.logoContainer.scrollLeft;
      e.preventDefault();
    },
    stopDrag() {
      this.isDragging = false;
    },
    onDrag(e) {
      if (!this.isDragging) return;
      e.preventDefault();
      const x = e.type === 'mousemove' ? e.pageX : e.touches[0].pageX;
      const walk = (x - this.startX) * 2;
      this.$refs.logoContainer.scrollLeft = this.initialScrollLeft - walk;
    },
  }
};
</script>

<style scoped>
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>