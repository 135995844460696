<template>
  <div v-for="product in products" :key="product.id"  class="group border rounded-lg pb-2">
    <router-link :to="{ name: 'ProductPage', params: { slug: product.slug, category_slug: product.category.slug } }" :target="isDesktop()">
      <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden xl:aspect-h-8 xl:aspect-w-7">
        <img :src="`${product.thumb}`" :alt="product.name" class="h-full w-full object-contain object-center group-hover:opacity-75 p-4" />
      </div>
      <h3 class="mt-4 text-sm text-gray-700 px-4">{{ product.name }}</h3>
    </router-link>
    <p class="mt-1 text-lg font-medium text-gray-900 px-4">
      {{ formatPrice(product.price) }} Ft
    </p>
    <div class="mt-4 mb-2 flex px-4" v-if="product.price !== null">
      <button type="submit" class="flex-1 rounded-md border border-transparent bg-gray-900 px-8 py-3 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2 focus:ring-offset-gray-50" @click="addToCartSubmit(product)"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="size-6 m-auto" fill="#fff"><path d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z"/></svg></button>

      <button type="button" class="ml-4 flex items-center justify-center rounded-md px-3 py-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500" @click="toggleFavoriteChange(product)">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="size-6" v-if="$store.getters.isFavorite(product.id)"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="size-6" v-else><path d="M225.8 468.2l-2.5-2.3L48.1 303.2C17.4 274.7 0 234.7 0 192.8l0-3.3c0-70.4 50-130.8 119.2-144C158.6 37.9 198.9 47 231 69.6c9 6.4 17.4 13.8 25 22.3c4.2-4.8 8.7-9.2 13.5-13.3c3.7-3.2 7.5-6.2 11.5-9c0 0 0 0 0 0C313.1 47 353.4 37.9 392.8 45.4C462 58.6 512 119.1 512 189.5l0 3.3c0 41.9-17.4 81.9-48.1 110.4L288.7 465.9l-2.5 2.3c-8.2 7.6-19 11.9-30.2 11.9s-22-4.2-30.2-11.9zM239.1 145c-.4-.3-.7-.7-1-1.1l-17.8-20-.1-.1s0 0 0 0c-23.1-25.9-58-37.7-92-31.2C81.6 101.5 48 142.1 48 189.5l0 3.3c0 28.5 11.9 55.8 32.8 75.2L256 430.7 431.2 268c20.9-19.4 32.8-46.7 32.8-75.2l0-3.3c0-47.3-33.6-88-80.1-96.9c-34-6.5-69 5.4-92 31.2c0 0 0 0-.1 .1s0 0-.1 .1l-17.8 20c-.3 .4-.7 .7-1 1.1c-4.5 4.5-10.6 7-16.9 7s-12.4-2.5-16.9-7z"/></svg>
        <span class="sr-only">Kedvencekhez adom</span>
      </button>
    </div>
  </div>

  <AddToCartModal :open="isAddToCartModalOpen" @close="closeAddToCartModal" />

  <!-- Favorite alert -->
  <Alert :isVisible="showFavoriteAlert" message="Sikeresen hozzáadva a kedvencekhez!" :progress="compareProgress" />
  <Alert :isVisible="!showFavoriteAlert && favoriteAlertChanged" type="error" message="Sikeresen törölve a kedvencekből!" :progress="compareProgress" />
</template>

<script setup>
function formatPrice(price) {
  return price ? price.toLocaleString('hu-HU') : 'N/A';
}
function isDesktop() {
  return window.innerWidth >= 1024 ? '_blank' : null;
}
</script>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ProductList',
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      isAddToCartModalOpen: false,
      showCompareAlert: false,
      compareAlertChanged: false,
      compareProgress: 0,
      showFavoriteAlert: false,
      favoriteAlertChanged: false,
    }
  },
  methods: {
    ...mapActions(['addToCart', 'toggleFavorite']),
    toggleFavoriteChange(product) {
      this.toggleFavorite({
        id: product.id,
        name: product.name,
        price: product.price,
        image: product.thumb,
        quantity: 1,
        category: product.category,
        link: '/termek/' + product.category.slug + '/' + product.slug,
        vendor: product.vendor.id,
        store_name: product.vendor.store_name,
        delivery_cost: product.delivery_cost,
        delivery_time: product.delivery_time,
        payment_types: Object.keys(product.vendor.payment_types_json),
      });

      if(this.$store.getters.isFavorite(product.id)){
        this.showFavoriteAlert = true;
        this.favoriteAlertChanged = false;
        this.compareProgress = 0;

        const interval = setInterval(() => {
          if (this.compareProgress < 100) {
            this.compareProgress += 1;
          } else {
            clearInterval(interval)
            setTimeout(() => {
              this.showFavoriteAlert = false;
              this.compareProgress = 0;
            }, 500)
          }
        }, 30);

        setTimeout(() => {
          this.showFavoriteAlert = false;
        }, 3000);
      }else{
        this.showFavoriteAlert = false;
        this.favoriteAlertChanged = true;
        this.compareProgress = 0;

        const interval = setInterval(() => {
          if (this.compareProgress < 100) {
            this.compareProgress += 1;
          } else {
            clearInterval(interval);
            setTimeout(() => {
              this.showFavoriteAlert = false;
              this.compareProgress = 0;
              this.favoriteAlertChanged = false;
            }, 500);
          }
        }, 30);

        setTimeout(() => {
          this.showFavoriteAlert = false;
          this.favoriteAlertChanged = false;
        }, 3000);
      }
    },
    closeAddToCartModal() {
      this.isAddToCartModalOpen = false;
    },
    addToCartSubmit(product) {
      this.addToCart({
        id: product.id,
        name: product.name,
        price: product.price,
        image: product.thumb,
        quantity: 1,
        category: product.category,
        link: '/termek/' + product.category.slug + '/' + product.slug,
        vendor: product.vendor.id,
        store_name: product.vendor.store_name,
        delivery_cost: product.delivery_cost,
        delivery_time: product.delivery_time,
        payment_types: Object.keys(product.vendor.payment_types_json),
      });
      this.isAddToCartModalOpen = true;
      this.gtagAddToCart(product);
    },
    gtagAddToCart(product){
      if (window.gtag) {
        window.gtag('event', 'add_to_cart', {
          currency: "HUF",
          value: product.price,
          items: [{
            item_id: product.id,
            item_name: product.name,
            price: product.price,
            quantity: 1
          }]
        });
      }
    },
  }
}
</script>