import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios';
import router from './router'
import store from './store'
import './assets/tailwind.css'
import i18n from './i18n'
import { createHead } from '@vueuse/head'

//components
import AddToCartModal from './components/AddToCartModal.vue'; 
import Alert from './components/Alert.vue'; 

//lazyload
//inter font

const token = localStorage.getItem('authToken');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const head = createHead()

createApp(App)
  .use(router)
  .use(store) 
  .use(i18n)
  .use(head)
  .component('AddToCartModal', AddToCartModal)
  .component('Alert', Alert)
  .mount('#app');