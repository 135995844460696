<template>
  <div class="bg-white" v-if="recentlyViewedProducts.length > 0">
    <main class="mx-auto mt-8 max-w-2xl px-4 pb-16 sm:px-6 sm:pb-24 lg:max-w-7xl lg:px-8">
      <section aria-labelledby="related-heading" class="mt-16 sm:mt-24">
        <h2 id="korabban-megtekintett-termekek" class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Korábban megtekintett termékek</h2>
        <div class="mx-auto max-w-2xl lg:max-w-7xl mt-16">
          <h2 class="sr-only">Products</h2>
          <div>
            <div class="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              <ProductList :products="recentlyViewedProducts" />
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProductList from '@/components/ProductList.vue';

export default {
  name: 'RecentlyViewedProductsPage',
  components: {
    ProductList,
  },
  computed: {
    ...mapGetters(['recentlyViewedProducts']), 
  },
}
</script>
